<template>
  <div>
    <b-card no-body>
      <!-- create -->
      <div
        class="p-1"
        v-if="
          (currentUser.roles[0].name === 'super_admin' ||
            currentUser.roles[0].name === 'tec') &&
          maintenance.status.id !== 7
        "
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-link
            :to="{
              name: 'createIncidence',
              params: { maintenance_id: maintenance.id },
            }"
          >
            <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
              {{ "+ Crear Incidencia" }}
            </b-button>
          </b-link>
        </div>
      </div>
      <hr class="m-0" />
      <!-- filters -->

      <!-- tabla -->
      <b-table
        hover
        responsive
        :items="incidences"
        :fields="columns"
        class="table-white"
      >
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'viewIncidence', params: { id: data.item.id } }"
            class="link"
          >
            {{ data.item.name }}
          </b-link>
        </template>
        <template #cell(asset)="data">
          <b-link
            :to="{ name: 'viewAsset', params: { id: data.item.asset.id } }"
            class="link"
          >
            {{ data.item.asset.product_name }}
          </b-link>
        </template>
        <template #cell(user)="data">
          <b-link
            v-if="data.item.user"
            :to="{ name: 'viewUser', params: { id: data.item.user.id } }"
            class="link"
          >
            <span>{{ data.item.user.name }} {{ data.item.user.surname }}</span>
          </b-link>
        </template>
        <template #cell(date)="data">
          <span>{{ dateColumn(data.item.date) }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge
            class="px-2"
            :variant="statusIncidenceVariants[data.item.apto]"
            style="top: -3px"
          >
            {{ statusIncidenceTexts[data.item.apto] }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <span>
            <b-link
              v-if="maintenance.status.id !== 7"
              :to="{ name: 'viewIncidence', params: { id: data.item.id } }"
              class="d-inline-block px-50 text-dark"
            >
              <feather-icon icon="EyeIcon" />
            </b-link>
            <b-link
              v-if="currentUser.roles[0].name === 'super_admin' && maintenance.status.id !== 7"
              :to="{ name: 'editIncidence', params: { id: data.item.id } }"
              class="d-inline-block px-50 text-dark"
            >
              <feather-icon icon="Edit2Icon" />
            </b-link>
          </span>
        </template>
      </b-table>
      <!-- paginador  -->
      <div
        v-if="totalItems > pageLength"
        class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2"
      >
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap"> {{ "Por página" }}: </span>
          <b-form-select
            v-model="pageLength"
            :options="pages"
            class="ml-50 mr-1"
            @input="handlePageChange"
          />
          <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
        </div>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BCard,
  BTable,
  BLink,
  BButton,
  BCol,
  BRow,
  BFormSelect,
  BPagination,
  BBadge,
  BFormGroup,
} from "bootstrap-vue";
import { config } from "@/shared/app.config";
import vSelect from "vue-select";
import incidences from "@/store/incidences";
import { TimestampToFlatPickr } from "@/libs/helpers";

export default {
  components: {
    BTable,
    BCard,
    BLink,
    BCol,
    BRow,
    BButton,
    BFormSelect,
    BPagination,
    BFormGroup,
    BBadge,
    vSelect,
    TimestampToFlatPickr,
  },
  data() {
    return {
      currentPage: 1,
      pageLength: 10,
      pages: ["10", "15", "25"],
      searchTerm: "",
      columns: [
        {
          label: "Fecha de alta",
          key: "date",
        },
        {
          label: "Nombre",
          key: "name",
        },
        {
          label: "Equipo asociado",
          key: "asset",
        },
        {
          label: "Técnico",
          key: "user",
        },
        {
          label: "Estado",
          key: "status",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      statusIncidenceVariants: config.statusIncidenceVariants,
      statusIncidenceTexts: config.statusIncidenceTexts,
    };
  },
  computed: {
    ...mapGetters({
      maintenance: 'maintenances/getMaintenance',
      incidences: "incidences/getItems",
      totalItems: "incidences/getTotalItems",
      currentUser: "auth/getUser",
    }),
  },
  watch: {
    status() {
      this.currentPage = 1;
      this.chargeData();
    },
    zone() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  methods: {
    ...mapActions({
      getIncidences: "incidences/getListIncidences",
    }),
    dateColumn(time) {
      return TimestampToFlatPickr(time);
    },
    chargeData() {
      let pageLength = this.pageLength ? this.pageLength : 1;

      this.getIncidences({
        project: this.maintenance.project_id,
        maintenance_id: this.maintenance.id,
        page: this.currentPage,
        per_page: pageLength,
        search: this.searchTerm,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
